// Modules imports
import { Typography, Paper, Grid, Divider } from "@mui/material";
import PropTypes from "prop-types";

// App components
import StatusList from "../Status/StatusList";

/**
 * Component ProjectsPlanningDetails
 *
 * displays project details of project passed in props
 *
 * @param {*} props
 * -
 * - project : project object of projects array
 *
 * @returns JSX.Element
 */
export default function ProjectsPlanningDetails({ project }) {
  return (
    <Paper data-cy="projectsPlanningDetails" elevation={0} sx={{ padding: 2, border: 0.1, borderColor: "lightGray" }}>
      <Grid container rowSpacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5">{project.name}</Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"end"}>
          <StatusList status={project.status} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6} style={{ paddingTop: 4 }}>
          <Typography variant="overline">{`début prévu le : ${new Date(project.startDate).toLocaleDateString("fr-CH", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}`}</Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="end" style={{ paddingTop: 4 }}>
          <Typography variant="overline">{`fin prévue le : ${new Date(project.endDate).toLocaleDateString("fr-CH", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontWeight={"bold"} mr={2} gutterBottom>
            CHEF DE PROJET
          </Typography>
          <Typography gutterBottom>
            {project.projectManager
              ? project.projectManager.firstname + " " + project.projectManager.lastname
              : "Aucun"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontWeight={"bold"} mr={2} gutterBottom>
            SPONSOR
          </Typography>
          <Typography gutterBottom>
            {project.sponsor ? project.sponsor.firstname + " " + project.sponsor.lastname : "Aucun"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="bold" textAlign={"start"} gutterBottom>
            DESCRIPTION
          </Typography>
          <Typography paragraph align={"justify"}>
            {project.description ? project.description : "Aucune"}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

ProjectsPlanningDetails.propTypes = {
  project: PropTypes.object.isRequired,
};

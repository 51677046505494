// Modules import
import PropTypes from "prop-types";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Button, Divider, Grid, Paper, Toolbar, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

// imports to handle dates
import DateFnsUtils from "@date-io/date-fns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

// App components
import ProjectDetailsMilestoneItemAdmin from "./ProjectDetailsMilestoneItemAdmin";

/**
 * Component MilestonesListProjectDetailsAdmin
 *
 * Display project milestones list with the admin context
 *
 * @param {*} props
 * -
 * - milestones : milestone part of the project document from database
 * - handleChangeProject : callback function to update project object
 *
 * @returns JSX.Element
 */
export default function ProjectDetailsMilestonesListAdmin(props) {
  // eslint-disable-next-line no-unused-vars
  const { milestones, handleChangeProject } = props;

  // pre-processing for project object update
  const handleChangeMilestone = (index, key, newValue) => {
    let updatedMilestones = milestones;
    updatedMilestones[index][key] = newValue;
    handleChangeProject("milestones", updatedMilestones);
  };

  // add empty milestone to project object
  const createNewMilestone = () => {
    let updatedMilestones = milestones;
    updatedMilestones.unshift({
      name: "",
      startDate: null,
      endDate: null,
      status: 0,
    });
    handleChangeProject("milestones", updatedMilestones);
  };

  // remove a milestone of project object
  const deleteOneMilestone = (index) => {
    let updatedMilestones = milestones;
    updatedMilestones.splice(index, 1);
    handleChangeProject("milestones", updatedMilestones);
  };

  // dnd
  const moveItem = (dragIndex, hoverIndex) => {
    let updatedMilestones = milestones;
    handleChangeProject(
      "milestones",
      update(updatedMilestones, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, updatedMilestones[dragIndex]],
        ],
      })
    );
  };

  const renderItem = (milestone, index, startId) => {
    return (
      <Grid item xs={12} key={startId}>
        <ProjectDetailsMilestoneItemAdmin
          startId={startId}
          milestone={milestone}
          index={index}
          moveItem={moveItem}
          handleChangeMilestone={handleChangeMilestone}
          deleteOneMilestone={deleteOneMilestone}
        />
        <Divider variant="middle" light />
      </Grid>
    );
  };

  // DND PBS TEST
  // const moveItem = useCallback((dragIndex, hoverIndex) => {
  //   let updatedMilestones = milestones;
  //   handleChangeProject(
  //     "milestones",
  //     update(updatedMilestones, {
  //       $splice: [
  //         [dragIndex, 1],
  //         [hoverIndex, 0, updatedMilestones[dragIndex]],
  //       ],
  //     })
  //   );
  // }, []);

  // const renderItem = useCallback((milestone, index, startId) => {
  //   return (
  //     <Grid item xs={12} key={startId}>
  //       <ProjectDetailsMilestoneItemAdmin
  //         startId={startId}
  //         milestone={milestone}
  //         index={index}
  //         moveItem={moveItem}
  //         handleChangeMilestone={handleChangeMilestone}
  //         deleteOneMilestone={deleteOneMilestone}
  //       />
  //     </Grid>
  //   );
  // }, []);

  ///////////////////////// Component render /////////////////////////////
  return (
    <Paper data-cy="MilestonesListAdminPaper" elevation={0} variant="outlined">
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        {/* MILESTONES LIST CONTAINER */}
        <Grid container>
          {/* MILESTONES LIST TOP BAR */}
          <Grid item xs={12}>
            <Toolbar
              sx={{
                justifyContent: "space-between",
                backgroundColor: "default",
              }}
            >
              <Typography variant="h6">Étapes et jalons</Typography>
              <Button
                data-cy="newMilestoneButton"
                variant="text"
                color="primary"
                startIcon={<Add />}
                onClick={createNewMilestone}
              >
                nouveau jalon / étape
              </Button>
            </Toolbar>
          </Grid>
          {/* END MILESTONES LIST TOP BAR */}
          {/* MILESTONES LIST HEADER */}
          <Grid item xs={12}>
            <Grid container py={2} px={4}>
              <Grid item xs={3}>
                <Typography textAlign={"left"}>Name</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography textAlign={"left"}>startDate</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography textAlign={"left"}>endDate</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography textAlign={"center"}>status</Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Grid>

          {/* END MILESTONES LIST HEADER */}
          {/* MILESTONES LIST BODY ITEMS */}
          <Grid item xs={12}>
            <Divider light />
            <Grid id={"milestonesListBodyItems"} container>
              <DndProvider backend={HTML5Backend}>
                {milestones.map((milestone, index) => {
                  const startId = "milestone_" + milestone._id + "_" + index;
                  return renderItem(milestone, index, startId);
                })}
              </DndProvider>
            </Grid>
          </Grid>
          {/* END MILESTONES LIST BODY ITEMS */}
        </Grid>
        {/* END MILESTONES LIST CONTAINER */}
      </LocalizationProvider>
    </Paper>
  );
}

ProjectDetailsMilestonesListAdmin.propTypes = {
  milestones: PropTypes.array.isRequired,
  handleChangeProject: PropTypes.func.isRequired,
};

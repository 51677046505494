// Modules imports
import PropTypes from "prop-types";
import { Grid, Tooltip, Typography, Box } from "@mui/material";
import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";

// App components
import OptionsProjectButton from "../components/buttons/OptionsProjectButton";
import SaveProjectButton from "../components/buttons/SaveProjectButton";
import StatusList from "../components/Status/StatusList";
import MilestonesListProjectDetailsManager from "../components/tables/MilestonesListProjectDetailsManager";
import DownloadProjectSheetButton from "../components/buttons/DownloadProjectSheetButton";
import { useEffect, useState } from "react";

/**
 * Component ProjectDetailsManager
 *
 * Display project details with the manager context (limited rights for updating project data)
 *
 * @param {*} props
 * -
 * - getProject : project document from database
 * - handleNavigateBack : callback function to navigate back from projectdetails route
 * - handleChangeProject : callback function to update project object
 */
export default function ProjectdetailsManager(props) {
  const { getProject, handleNavigateBack, handleChangeProject } = props;
  const [milestones] = useState(getProject.milestones);

  useEffect(() => {
    let nm = milestones.map((milestone) => {
      milestone.startDate = new Date(milestone.startDate);
      milestone.endDate = new Date(milestone.endDate);
      return milestone;
    });
    handleChangeProject("milestones", nm);
  }, [milestones]);

  // handle the database formatted date parsing
  const parsedDate = (dateDB) => {
    const dateObject = new Date(dateDB);
    return dateObject.toLocaleDateString();
  };

  // concat project manager name
  const fullName = (person) => {
    return person.firstname && person.lastname ? person.firstname + " " + person.lastname : "undefined";
  };

  ///////////////////////// Component render /////////////////////////////
  return (
    <Grid container spacing={2} rowSpacing={3}>
      {/* FORM TITLE / PROJECT STATUS */}
      <Grid
        data-cy="projectDetailsManagerTitleAndStatus"
        item
        xs={8}
        sx={{
          display: "inline-flex",
          justify: "flex-start",
          alignItems: "center",
        }}
      >
        {/* TITLE */}
        <Typography variant="h5" sx={{ mr: 2 }}>
          Détails : {getProject.name}
        </Typography>
        {/* PROJECT STATUS */}
        <StatusList status={getProject.status} />
      </Grid>
      {/* END FORM TITLE / PROJECT STATUS */}
      {/* SAVE / OPTIONS BUTTONS */}
      <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <SaveProjectButton project={getProject} />
        <OptionsProjectButton project={getProject} handleNavigateBack={handleNavigateBack} isUserAdmin={false} />
      </Grid>
      {/* END SAVE / OPTIONS BUTTONS */}
      {/* PROJECT CONFIDENTIAL */}
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
        style={{ paddingTop: 4 }}
      >
        {/* CONFIDENTIAL */}
        <Box id="project_confidential" color={"gray"} display={"inline-flex"}>
          <Tooltip title="confidentialité du projet">
            {getProject.confidential ? <LockOutlined /> : <LockOpenOutlined />}
          </Tooltip>
          <Box display={"inline-flex"}>
            <Typography variant="button" mt={0.1} ml={1}>
              {getProject.confidential ? "non-public" : "public"}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {/* END PROJECT CONFIDENTIAL */}
      {/* PROJECT CREATED AT */}
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }} style={{ paddingTop: 4 }}>
        <Typography id="project_createdAt" variant="overline" color="gray">
          Date de création : {getProject.createdAt ? parsedDate(getProject.createdAt) : "undefined"}
        </Typography>
      </Grid>
      {/* END PROJECT CREATED AT */}
      {/* PROJECT MANAGER */}
      <Grid
        item
        xs={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Typography fontWeight={"bold"} textAlign="left" gutterBottom>
          CHEF DU PROJET
        </Typography>
        <Typography id="project_projectManager" textAlign="left" gutterBottom>
          {getProject.projectManager ? fullName(getProject.projectManager) : "Aucun"}
        </Typography>
      </Grid>
      {/* END PROJECT MANAGER */}
      <Grid item xs={2} />
      {/* PROJECT SPONSOR */}
      <Grid
        item
        xs={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Typography fontWeight={"bold"} textAlign="left" gutterBottom>
          SPONSOR
        </Typography>
        <Typography id="project_sponsor" textAlign="left" gutterBottom>
          {getProject.sponsor ? fullName(getProject.sponsor) : "Aucun"}
        </Typography>
      </Grid>
      {/* END PROJECT SPONSOR */}
      {/* DOWNLOAD PROJECT SHEET */}
      <Grid
        item
        xs={12}
        sx={{
          display: "inline-flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        style={{ paddingTop: 0 }}
      >
        <DownloadProjectSheetButton projectId={getProject._id} projectSheet={getProject.projectSheet} textButton />
      </Grid>
      {/* END DOWNLOAD PROJECT SHEET */}
      {/* PROJECT MILESTONES LIST */}
      <Grid item xs={12} style={{ paddingTop: 4 }}>
        <MilestonesListProjectDetailsManager
          projectMilestones={getProject.milestones}
          handleChangeProject={handleChangeProject}
        />
      </Grid>
      {/* END PROJECT MILESTONES LIST */}
      {/* PROJECT DESCRIPTION */}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Typography fontWeight="bold" textAlign={"start"} gutterBottom>
          DESCRIPTION
        </Typography>
        <Typography id="project_description" paragraph align={"justify"}>
          {getProject.description ? getProject.description : "Aucune"}
        </Typography>
      </Grid>
      {/* END PROJECT DESCRIPTION */}
      {/* PROJECT COPIL MEMBERS */}
      <Grid
        item
        id="project_copilMembers"
        xs={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Typography fontWeight={"bold"} gutterBottom textAlign={"start"}>
          MEMBRES DU COPIL
        </Typography>
        {getProject.copilMembers.length < 1 ? (
          <Typography textAlign={"start"}>Aucun</Typography>
        ) : (
          getProject.copilMembers.map((member) => {
            return (
              <Typography textAlign={"start"} key={getProject._id + "_copilMember_" + member.email}>
                {fullName(member)}
              </Typography>
            );
          })
        )}
      </Grid>
      {/* END PROJECT COPIL MEMBERS */}
      <Grid item xs={2} />
      {/* PROJECT COPRO MEMBERS */}
      <Grid
        item
        id="project_coproMembers"
        xs={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Typography fontWeight={"bold"} gutterBottom textAlign={"start"}>
          MEMBRES DU COPRO
        </Typography>
        {getProject.coproMembers.length < 1 ? (
          <Typography textAlign={"start"}>Aucun</Typography>
        ) : (
          getProject.coproMembers.map((member) => {
            return (
              <Typography textAlign={"start"} key={getProject._id + "_coproMember_" + member.email}>
                {fullName(member)}
              </Typography>
            );
          })
        )}
      </Grid>
      {/* END PROJECT COPRO MEMBERS */}
    </Grid>
  );
}

ProjectdetailsManager.propTypes = {
  getProject: PropTypes.object.isRequired,
  handleNavigateBack: PropTypes.func.isRequired,
  handleChangeProject: PropTypes.func.isRequired,
};

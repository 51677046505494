/* eslint-disable no-unused-vars */
// Modules imports
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  Box,
  MenuItem,
  Select,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Clear } from "@mui/icons-material";

// Requests imports
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";
import apiClient from "../services/axiosCfg";

// App components
import StatusList from "../components/Status/StatusList";
import AutocompleteOnePerson from "../components/autocompletes/AutocompleteOnePerson";
import AutocompleteMultiPersons from "../components/autocompletes/AutocompleteMultiPersons";
import SaveProjectButton from "../components/buttons/SaveProjectButton";
import OptionsProjectButton from "../components/buttons/OptionsProjectButton";
import ProjectDetailsMilestonesListAdmin from "../components/tables/ProjectDetailsMilestonesListAdmin";
import UploadProjectSheetButton from "../components/buttons/UploadProjectSheetButton";
import DownloadProjectSheetButton from "../components/buttons/DownloadProjectSheetButton";

/**
 * Component ProjectDetailsManager
 *
 * Display project details with the admin context
 *
 * @param {*} props
 * -
 * - getProject : project document from database
 * - handleNavigateBack : callback function to navigate back from projectdetails route
 * - handleChangeProject : callback function to update project object
 *
 * @returns JSX.Element
 */
export default function ProjectDetailsAdmin(props) {
  const { getProject, handleNavigateBack, handleChangeProject } = props;
  const [projectNameInputError, setProjectNameInputError] = useState(false); // project name input error state
  const [apiAdPeopleToken, setApiAdPeopleToken] = useState("");

  // PREPARE REQUEST HEADERS WITH KEYCLOAK TOKEN
  const config = {
    headers: { Authorization: "Bearer " + useKeycloak().keycloak.token },
  };

  // Request
  const { isLoading, refecth } = useQuery(
    "getApiAdPeopleToken",
    async () => {
      return await apiClient.get("/special/apiAdPeopleToken", config);
    },
    {
      refetchOnWindowFocus: false,
      enabled: true,
      onSuccess: (res) => {
        setApiAdPeopleToken(res.data.token);
      },
      onError: (err) => {
        throw err;
      },
    }
  );

  // handle the database formatted date parsing
  const parsedDate = (dateDB) => {
    const dateObject = new Date(dateDB);
    return dateObject.toLocaleDateString();
  };

  ///////////////////////// Component render /////////////////////////////
  return (
    <form id="projectDetailsForm">
      <Grid
        data-cy="projectDetailsAdminContainer"
        container
        spacing={2}
        rowSpacing={3}
      >
        {/* FORM TITLE / PROJECT STATUS */}
        <Grid
          item
          xs={8}
          sx={{
            display: "inline-flex",
            justify: "flex-start",
            alignItems: "center",
          }}
        >
          {/* TITLE */}
          <Typography variant="h5" sx={{ mr: 2 }}>
            Détails : {getProject.name}
          </Typography>
          {/* STATUS */}
          <Select
            id={"project_status"}
            variant="standard"
            disableUnderline
            value={getProject.status}
            onChange={(event) => {
              handleChangeProject("status", event.target.value);
            }}
          >
            <MenuItem key={"project_status_MI_0"} value={0}>
              <StatusList status={0} />
            </MenuItem>
            <MenuItem key={"project_status_MI_1"} value={1}>
              <StatusList status={1} />
            </MenuItem>
            <MenuItem key={"project_status_MI_2"} value={2}>
              <StatusList status={2} />
            </MenuItem>
          </Select>
        </Grid>
        {/* END FORM TITLE / PROJECT STATUS */}
        {/* SAVE / OPTIONS BUTTONS */}
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SaveProjectButton project={getProject} />
          <OptionsProjectButton
            project={getProject}
            handleNavigateBack={handleNavigateBack}
            isUserAdmin={true}
          />
        </Grid>
        {/* END SAVE / OPTIONS BUTTONS */}
        {/* PROJECT CONFIDENTIAL */}
        <Grid
          item
          xs={6}
          display={"flex"}
          justifyContent={"flex-start"}
          style={{ paddingTop: 4 }}
        >
          <Box
            display="inline-flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            {/* CONFIDENTIAL */}
            <FormControlLabel
              label="Projet non-public"
              labelPlacement="end"
              control={
                <Switch
                  id="project_confidential"
                  checked={getProject.confidential}
                  inputProps={{
                    "aria-label": "switch projet non public",
                  }}
                  onChange={(event) =>
                    handleChangeProject("confidential", event.target.checked)
                  }
                />
              }
            />
          </Box>
        </Grid>
        {/* END PROJECT CONFIDENTIAL */}
        {/* PROJECT CREATED AT */}
        <Grid
          item
          xs={6}
          display="inline-flex"
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingTop: 4 }}
        >
          <Typography id="project_createdAt" variant="subtitle1">
            Date de création :{" "}
            {getProject.createdAt
              ? parsedDate(getProject.createdAt)
              : "undefined"}
          </Typography>
        </Grid>
        {/* END PROJECT CREATED AT */}
        {/* PROJECT NAME */}
        <Grid
          item
          xs={5}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <TextField
            id="project_name"
            error={projectNameInputError}
            helperText={"obligatoire"}
            label="Nom du projet"
            variant="standard"
            fullWidth
            value={getProject.name}
            onChange={(event) => {
              setProjectNameInputError(!event.target.value);
              handleChangeProject("name", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2} />
        {/* END PROJECT NAME */}
        {/* DOWNLOAD / UPLOAD OF PROJECT SHEET */}
        <Grid
          item
          xs={5}
          sx={{
            display: "inline-flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* UPLOAD TEXT */}
          <TextField
            id="project_projectSheet"
            label="Fiche du projet"
            InputProps={{
              readOnly: true,
              endAdornment: getProject.projectSheet && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleChangeProject("projectSheet", null)}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type="search"
            value={
              getProject.projectSheet ? getProject.projectSheet : "Aucune fiche"
            }
            variant="standard"
            helperText=" "
            sx={{ marginRight: 2 }}
            fullWidth
          />
          {/* UPLOAD BUTTON */}
          <UploadProjectSheetButton
            projectId={getProject._id}
            handleChangeProject={handleChangeProject}
          />
          {/* DOWNLOAD */}
          <DownloadProjectSheetButton
            projectId={getProject._id}
            projectSheet={getProject.projectSheet}
          />
        </Grid>
        {/* END DOWNLOAD / UPLOAD OF PROJECT SHEET */}
        {/* PROJECT MANAGER */}
        <Grid
          item
          xs={5}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          {apiAdPeopleToken && (
            <AutocompleteOnePerson
              person={getProject.projectManager}
              textInputLabel="Chef du projet"
              callbackFunction={handleChangeProject}
              callbackObjectKey="projectManager"
              apiAdPeopleToken={apiAdPeopleToken}
            />
          )}
        </Grid>
        {/* END PROJECT MANAGER */}
        <Grid item xs={2} />
        {/* PROJECT SPONSOR */}
        <Grid
          item
          xs={5}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          {apiAdPeopleToken && (
            <AutocompleteOnePerson
              person={getProject.sponsor}
              textInputLabel="Sponsor"
              callbackFunction={handleChangeProject}
              callbackObjectKey="sponsor"
              apiAdPeopleToken={apiAdPeopleToken}
            />
          )}
        </Grid>
        {/* END PROJECT SPONSOR */}
        {/* PROJECT DESCRIPTION */}
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <TextField
            id="project_description"
            label="Description"
            helperText="visible par tout le monde"
            placeholder="Description"
            multiline
            fullWidth
            variant="standard"
            value={getProject.description}
            onChange={(event) =>
              handleChangeProject("description", event.target.value)
            }
          />
        </Grid>
        {/* END PROJECT DESCRIPTION */}
        {/* PROJECT COMMENTS */}
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <TextField
            id="project_comments"
            label="Commentaires"
            placeholder="Commentaires"
            helperText="visible uniquement par les admins"
            multiline
            fullWidth
            variant="standard"
            value={getProject.comments}
            onChange={(event) =>
              handleChangeProject("comments", event.target.value)
            }
          />
        </Grid>
        {/* END PROJECT COMMENTS */}
        {/* PROJECT COPIL MEMBERS */}
        <Grid
          item
          xs={5}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          {apiAdPeopleToken && (
            <AutocompleteMultiPersons
              persons={getProject.copilMembers}
              textInputLabel="Membres du COPIL"
              callbackFunction={handleChangeProject}
              callbackObjectKey="copilMembers"
              apiAdPeopleToken={apiAdPeopleToken}
            />
          )}
        </Grid>
        {/* END PROJECT COPIL MEMBERS */}
        <Grid item xs={2} />
        {/* PROJECT COPRO MEMBERS */}
        <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {apiAdPeopleToken && (
            <AutocompleteMultiPersons
              persons={getProject.coproMembers}
              textInputLabel="Membres du COPRO"
              callbackFunction={handleChangeProject}
              callbackObjectKey="coproMembers"
              apiAdPeopleToken={apiAdPeopleToken}
            />
          )}
        </Grid>
        {/* END PROJECT COPRO MEMBERS */}
        {/* PROJECT MILESTONES LIST */}
        <Grid item xs={12}>
          <ProjectDetailsMilestonesListAdmin
            milestones={getProject.milestones}
            handleChangeProject={handleChangeProject}
          />
        </Grid>
        {/* END PROJECT MILESTONES LIST */}
      </Grid>
    </form>
  );
}

ProjectDetailsAdmin.propTypes = {
  getProject: PropTypes.object.isRequired,
  handleNavigateBack: PropTypes.func.isRequired,
  handleChangeProject: PropTypes.func.isRequired,
};

/* eslint-disable no-unused-vars */
import { FilterList } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Box, Collapse, IconButton, Select, TextField, Typography, MenuItem } from "@mui/material";
import { useState } from "react";
import StatusList from "../Status/StatusList";

export default function FilterListButton(props) {
  const { filterButtonFilters, setFilterButtonFilters, isUserAdmin } = props;
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
    // if (!checked) {
    //   document.getElementById("filterListButton").style.backgroundColor = "white";
    // } else {
    //   document.getElementById("filterListButton").style.backgroundColor = "transparent";
    // }
  };

  const handleChangeFilter = (index, value) => {
    let updatedFilters = filterButtonFilters.slice();
    updatedFilters[index] = value;
    setFilterButtonFilters(updatedFilters);
  };

  return (
    <Box id="filterListButton" display="inline-flex">
      <Collapse orientation="horizontal" timeout={200} in={checked}>
        <Box display="inline-flex" alignItems={"center"}>
          <TextField
            id="filterListName"
            variant="outlined"
            label="name"
            size="small"
            value={filterButtonFilters[0]}
            onChange={(event) => handleChangeFilter(0, event.target.value)}
            sx={{ mr: 2 }}
          />
          {isUserAdmin && (
            <TextField
              id="filterListProjectManager"
              variant="outlined"
              label="projectManager"
              size="small"
              value={filterButtonFilters[1]}
              onChange={(event) => handleChangeFilter(1, event.target.value)}
              sx={{ mr: 2 }}
            />
          )}
        </Box>
      </Collapse>
      <IconButton id="iconFilterListButton" onClick={handleChange}>
        <FilterList />
      </IconButton>
    </Box>
  );
}

FilterListButton.propTypes = {
  filterButtonFilters: PropTypes.array.isRequired,
  setFilterButtonFilters: PropTypes.func.isRequired,
  isUserAdmin: PropTypes.bool.isRequired,
};

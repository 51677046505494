import Keycloak from "keycloak-js";

const keycloakConfig = {
  url: "https://accounts.heig-vd.ch/auth",
  realm: "HEIG-VD",
  clientId: "bureau-projet.app",
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;

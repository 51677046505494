// Modules imports
import { useState } from "react";
import PropTypes from "prop-types";
import { Button, IconButton } from "@mui/material";
import { Download } from "@mui/icons-material";

// Requests imports
import { useKeycloak } from "@react-keycloak/web";
import JsFileDownloader from "js-file-downloader";
import apiClient from "../../services/axiosCfg";
// import { useQuery } from "react-query";

// App components
import SnackbarAlert from "../Status/SnackbarAlert";

/**
 * Component DownloadProjectSheetButton
 *
 * displays a download button and handle download file request
 *
 * @param {*} props
 * -
 * - projectId : id of the project to delete
 * - textButton : bool to choose button style (with text or not)
 * - projectSheet : string of the project projectSheet name
 *
 * @returns JSX.Element
 */
export default function DownloadProjectSheetButton(props) {
  const { textButton, projectId, projectSheet } = props;
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar open state (bool)

  // const query = useQuery(
  //   "downloadProjectSheet",
  //   () => {
  //     return apiClient.get(`/projects/${projectId}/file/${projectSheet}`, config);
  //   },
  //   {
  //     enabled: false,
  //     retry: false,
  //     refetchIntervalInBackground: false,
  //     refetchOnReconnect: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // PREPARE REQUEST
  const options = {
    url: `${apiClient.defaults.baseURL}/projects/${projectId}/file/${projectSheet}`,
    contentTypeDetermination: "header",
    headers: [
      {
        name: "Authorization",
        value: `Bearer ${useKeycloak().keycloak.token}`,
      },
    ],
    autoStart: false,
  };

  const downloadFile = new JsFileDownloader(options);

  const handleDownload = () => {
    downloadFile
      .start()
      .catch((err) => setError(err));
  };

  // useEffect(() => {
  //   setOpenSnackbar(query.isError);
  // }, [query.isError]);

  // useEffect(() => {
  //   setOpenSnackbar(query.isSuccess);
  // }, [query.isSuccess]);

  return (
    <>
      {/* BUTTON DOWNLOAD */}
      {textButton ? (
        <Button
          id="project_projectSheet_download"
          variant="text"
          endIcon={<Download />}
          disabled={!projectSheet}
          onClick={handleDownload}
        >
          Fiche du projet
        </Button>
      ) : (
        <IconButton
          id="project_projectSheet_download"
          color="primary"
          aria-label="download project sheet"
          disabled={!projectSheet}
          onClick={handleDownload}
        >
          <Download />
        </IconButton>
      )}
      {/* SNACKBARALERT REQUEST FEEDBACK */}
      {error && (
        <SnackbarAlert
          severity={"error"}
          title={"Erreur téléchargement de la fiche de projet"}
          message={error.toString()}
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          from={"DownloadProjectSheetButton"}
        />
      )}
    </>
  );
}

DownloadProjectSheetButton.propTypes = {
  textButton: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  projectSheet: PropTypes.string,
};

import { Chip } from "@mui/material";
import PropTypes from "prop-types";

/**
 * Component StatusList
 *
 * displays a a custom Status chip
 *
 * @param {*} props
 * -
 * - status : int => [0, 1, 2]
 *
 * @returns JSX.Element
 */
export default function StatusList({ status }) {
  const possibleStatus = ["planifié", "en cours", "terminé"];
  const colorStatus = ["planned", "progress", "done"];
  const statusInt = !status && status !== 0 ? -1 : status;
  const statusText = statusInt < 0 || statusInt > 2 ? "inconnu" : possibleStatus[statusInt];
  return <Chip label={statusText} color={statusInt === -1 ? "default" : colorStatus[statusInt]} />;
}

StatusList.propTypes = {
  status: PropTypes.number,
};

import { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Backdrop, CircularProgress } from "@mui/material";
import Router from "./routes/Router";

/**
 * Component KeycloakInit
 *
 * Handle keycloak initialization
 *
 * @returns JSX.Element
 */
export default function KeycloakInit() {
  const { initialized, keycloak } = useKeycloak();
  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(300);
    }

    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);

  if (!initialized) {
    // IF keycloak not initialized => Display backdrop loading circle
    return (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!initialized}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return <Router />;
}

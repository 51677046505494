// Modules imports
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, TimeScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";

import "chartjs-adapter-date-fns";
import { fr } from "date-fns/locale";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  BarElement,
  Title,
  Tooltip,
  ChartDataLabels,
  annotationPlugin,
  Legend
);

/**
 * Component DeleteProjectButton
 *
 * displays chartjs planning
 *
 * @param {*} props
 * -
 * - projects : projects array to display
 * - setDisplayProjectIndex : callback function that send back index of project to display
 * - planningOffset : array => [min, max] to set chart dateRange x axis
 *
 * @returns JSX.Element
 */
export default function PlanningGantt({ projects, setDisplayProjectIndex, planningOffset }) {
  const data = {
    labels: projects.map((project) => project.label),
    datasets: projects,
  };

  const options = {
    indexAxis: "y",
    barPercentage: 0.8,
    categoryPercentage: 1,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: false,
        position: "top",
        type: "time",
        adapters: {
          date: {
            locale: fr,
          },
        },
        time: {
          unit: "week",
          round: true,
        },
        min: planningOffset[0],
        max: planningOffset[1],
      },
      y: {
        stacked: true,
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        formatter: function (value, context) {
          return value ? context.chart.data.labels[context.dataIndex] : null;
        },
        display: "auto",
        clamp: true,
        // clipping: true,
      },
      tooltip: {
        enabled: false,
      },
      annotation: {
        annotations: {
          nowLine: {
            type: "line",
            scaleID: "x",
            value: new Date(new Date().toDateString()),
            borderColor: "gray",
            borderWidth: 2,
            drawTime: "beforeDraw",
            borderDash: [4, 4],
          },
        },
      },
    },
    onClick: function (evt, elm) {
      if (elm.length > 0) {
        setDisplayProjectIndex(elm[0].index);
      }
    },
  };

  return <Bar options={options} data={data} />;
}

PlanningGantt.propTypes = {
  projects: PropTypes.array.isRequired,
  setDisplayProjectIndex: PropTypes.func.isRequired,
  planningOffset: PropTypes.array.isRequired,
};

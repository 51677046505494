// Modules imports
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";

// App components
import SnackbarAlert from "../Status/SnackbarAlert";

// Requests imports
import { useKeycloak } from "@react-keycloak/web";
import { useMutation } from "react-query";
import apiClient from "../../services/axiosCfg";

/**
 * Component DeleteProjectButton
 *
 * displays a delete button and handle delete request to API
 *
 * @param {*} props
 * -
 * - projectId : id of the project to delete
 * - projectName : for display in dialog
 * - handleNavigateBack : go back after deletion
 *
 * @returns JSX.Element
 */
export default function DeleteProjectButton(props) {
  const { projectId, projectName, handleNavigateBack } = props;
  const [open, setOpen] = useState(false); // Dialog open state (bool)
  const [getOpenSnackbar, setOpenSnackbar] = useState(false); // Snackbar open state (bool)

  // PREPARE REQUEST HEADERS WITH KEYCLOAK TOKEN
  const config = {
    headers: { Authorization: "Bearer " + useKeycloak().keycloak.token },
  };
  // request for deleting project
  const projectMutation = useMutation(() => {
    return apiClient.delete("/projects/" + projectId, config);
  });

  // Handle confirm button
  const handleDelete = () => {
    projectMutation.mutate();
    setOpen(false);
  };

  // If delete project request error
  useEffect(() => {
    setOpenSnackbar(projectMutation.isError);
  }, [projectMutation.isError]);

  // If delete project request success
  useEffect(() => {
    if (projectMutation.isSuccess) {
      handleNavigateBack();
    }
    setOpenSnackbar(projectMutation.isSuccess);
  }, [projectMutation.isSuccess]);

  return (
    <>
      {/* Button delete */}
      <Box
        sx={{ display: "inline-flex", justifyContent: "center", alignItems: "center" }}
        onClick={() => setOpen(true)}
      >
        <Delete color="error" />
        <Typography color="error" ml={1}>
          {"Supprimer"}
        </Typography>
      </Box>
      {/* End Button delete */}
      {/* Dialog confirm deletion */}
      <Dialog
        data-cy="deleteProjectDialog"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Supprimer {projectName}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir supprimer {projectName} ?
          </DialogContentText>
          <DialogContentText color="error">Cette action est irréversible.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setOpen(false)}>
            Annuler
          </Button>
          <Button data-cy="confirmDeleteProjectDialog" variant="text" color="error" onClick={handleDelete} autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog confirm deletion */}
      {/* SnackbarAlerts (display status request) */}
      {projectMutation.isError && (
        <SnackbarAlert
          severity={"error"}
          title={"Erreur lors de la suppression du projet"}
          message={projectMutation.error.message}
          open={getOpenSnackbar}
          setOpen={setOpenSnackbar}
          from={"deleteProjectButton"}
        />
      )}
      {projectMutation.isSuccess && (
        <SnackbarAlert
          severity={"success"}
          title={"Projet supprimé"}
          message={"Le project à bien été supprimé"}
          open={getOpenSnackbar}
          setOpen={setOpenSnackbar}
          from={"deleteProjectButton"}
        />
      )}
      {/* End SnackbarAlerts */}
    </>
  );
}

DeleteProjectButton.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  handleNavigateBack: PropTypes.func.isRequired,
};

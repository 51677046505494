// Modules components
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

/**
 * Component EnhancedTableHead
 *
 * displays the head cells row of projects lists table
 *
 * @param {*} props
 * -
 * - orderBy : "asc" or "desc"
 * - sortBy : the column to sort on
 * - onRequestSort : callback function to sort the list
 * - headCells : label cells array
 *
 * @returns JSX.Element
 */
export default function EnhancedTableHead(props) {
  const { orderBy, sortBy, onRequestSort, headCells } = props;

  // onRequestSort callback handler
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // label cell alignment
  const handleAlign = (headCell) => {
    if (headCell === "createdAt" || headCell === "status") return "right";
    else return "left";
  };

  ///////////////////////// Component render /////////////////////////////
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={"headCell" + headCell}
              align={handleAlign(headCell)}
              sortDirection={sortBy === headCell ? orderBy : false}
            >
              <TableSortLabel
                active={sortBy === headCell}
                direction={sortBy === headCell ? orderBy : "asc"}
                onClick={createSortHandler(headCell)}
              >
                {headCell}
                {sortBy === headCell ? (
                  <Box component="span" sx={visuallyHidden}>
                    {orderBy === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  orderBy: PropTypes.oneOf(["asc", "desc"]).isRequired,
  sortBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
};

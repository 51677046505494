//Modules imports
import * as React from "react";
import { Box, Typography, CircularProgress, TextField, Autocomplete } from "@mui/material";
import PropTypes from "prop-types";

// Requests imports
import { useQuery } from "react-query";
import apiADPeople from "../../services/axiosADPeopleCfg";

/**
 * Component AutocompleteOnePerson
 *
 * Custom MUI Autocomplete to search in the AD people API (no multiselect)
 *
 * @param {*} props
 * -
 * - persons: array of people already selected
 * - textInputLabel: string for the input label
 * - callbackFunction: to save new selected people
 * - callbackObjectKey: string of the object Key where to store new selected people
 * - apiAdPeopleToken: token for api requests
 *
 * @returns JSX.Element
 */
export default function AutocompleteOnePerson(props) {
  const { person, textInputLabel, callbackFunction, callbackObjectKey, apiAdPeopleToken } = props;
  const [open, setOpen] = React.useState(false); // Autocomplete open state (bool)
  const [getErrors, setErrors] = React.useState(""); // handles Auocomplete errors
  const [options, setOptions] = React.useState([]); // array of options for selection
  const [txError, setTxError] = React.useState(false); // text error display state (bool)
  // Autocomplete input text
  const [getInputValue, setInputValue] = React.useState(
    // if exist put already selected people into input
    person ? person.firstname + " " + person.lastname : ""
  );

  // concat project manager name
  const fullName = (person) => {
    return person.firstname && person.lastname ? person.firstname + " " + person.lastname : "undefined";
  };

  // PREPARE REQUEST HEADERS
  const config = {
    headers: { "x-api-key": apiAdPeopleToken },
  };

  // Request get persons by name and email
  const { isLoading: isLoadingPersons, refetch: getPersons } = useQuery(
    "getPersons" + textInputLabel,
    async () => {
      return await apiADPeople.get("/search?s=" + getInputValue, config);
    },
    {
      enabled: false,
      onSuccess: (res) => {
        setOptions(res.data);
      },
      onError: (err) => {
        setErrors(err);
        console.log(err);
      },
    }
  );

  // On change input value query call api
  React.useEffect(() => {
    if (getInputValue !== "" && getInputValue !== null) {
      getPersons();
    }
  }, [getInputValue]);

  // empty persons list options when autocomplete close
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // React.useEffect(() => {
  //   if (callbackObjectKey === "projectManager") {
  //     setTxError(!person);
  //   }
  // }, [person]);

  ///////////////////////// Component render /////////////////////////////
  return getErrors ? (
    <Typography>{getErrors.toString()}</Typography>
  ) : (
    <Autocomplete
      id={"project_" + callbackObjectKey}
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => fullName(option) === fullName(value)}
      getOptionLabel={(option) => fullName(option)}
      options={options}
      filterOptions={(x) => x}
      loading={isLoadingPersons}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        if (callbackObjectKey === "projectManager") {
          setTxError(!newValue);
        }
        callbackFunction(callbackObjectKey, newValue);
      }}
      value={person ? person : null}
      inputValue={getInputValue}
      renderOption={(props, option) => {
        return (
          <li {...props} key={textInputLabel + "_li_" + option.email}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography>{fullName(option)}</Typography>
              <Typography color={"gray"}>{" (" + option.email + ")"}</Typography>
            </Box>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={txError}
          helperText={callbackObjectKey === "projectManager" ? "obligatoire" : " "}
          variant="standard"
          label={textInputLabel ? textInputLabel : "Person"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoadingPersons ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

AutocompleteOnePerson.propTypes = {
  person: PropTypes.object,
  textInputLabel: PropTypes.string,
  callbackFunction: PropTypes.func.isRequired,
  callbackObjectKey: PropTypes.string.isRequired,
  apiAdPeopleToken: PropTypes.string.isRequired,
};

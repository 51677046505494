// Modules imports
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { Add } from "@mui/icons-material";

// App components
import SnackbarAlert from "../Status/SnackbarAlert";

// Requests imports
import { useKeycloak } from "@react-keycloak/web";
import { useMutation } from "react-query";
import apiClient from "../../services/axiosCfg";

/**
 * Component NewProjectButton
 *
 * Handle creation of a new project in database
 *
 * @returns JSX.Element
 */
export default function NewProjectButton() {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar open state (bool)
  const [openDialog, setOpenDialog] = useState(false); // Dialog open state (bool)
  const [projectConfidential, setProjectConfidential] = useState(false); // new project object confidential state (bool)
  const [projectName, setProjectName] = useState(""); // new project object name (string)
  const [projectNameInputError, setProjectNameInputError] = useState(false);

  // PREPARE REQUEST HEADERS WITH KEYCLOAK TOKEN
  const config = {
    headers: { Authorization: "Bearer " + useKeycloak().keycloak.token },
  };

  // Request to create a new almost blank project in database
  const postProjectMutation = useMutation(() => {
    const body = {
      project: {
        name: projectName,
        confidential: projectConfidential,
        status: 0,
        archive: false,
      },
    };
    return apiClient.post("/projects", body, config);
  });

  const handleProjectCreation = () => {
    if (projectName) {
      postProjectMutation.mutate();
    }
  };

  // If request is error
  useEffect(() => {
    setOpenSnackbar(postProjectMutation.isError);
  }, [postProjectMutation.isError]);

  // If request is success
  useEffect(() => {
    setOpenSnackbar(postProjectMutation.isSuccess);
    if (postProjectMutation.isSuccess) {
      navigate("/projects/" + postProjectMutation.data.data._id);
    }
  }, [postProjectMutation.isSuccess]);

  ///////////////////////// Component render /////////////////////////////
  return (
    <>
      {/* BUTTON CREATE PROJECT */}
      <Button
        aria-label="nouveau projet"
        variant="text"
        color="primary"
        onClick={() => setOpenDialog(true)}
        startIcon={<Add />}
        sx={{ whiteSpace: "nowrap", minWidth: "auto" }}
      >
        Nouveau Projet
      </Button>
      {/* CREATION PROJECT DIALOG */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} data-cy="newProjectDialog">
        <DialogTitle id="alert-dialog-title">Créer un nouveau projet</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Veuillez saisir les informations initiales :
          </DialogContentText>
          {/* NEW PROJECT NAME */}
          <TextField
            autoFocus
            margin="dense"
            id="newProjectDialogName"
            label="Nom du projet"
            fullWidth
            variant="standard"
            value={projectName}
            error={projectNameInputError}
            helperText={"Obligatoire"}
            onChange={(event) => {
              setProjectNameInputError(!event.target.value);
              setProjectName(event.target.value);
            }}
          />
          {/* NEW PROJECT CONFIDENTIAL */}
          <FormControlLabel
            label="Projet non-public"
            labelPlacement="start"
            sx={{ ml: 0, mt: 2 }}
            control={
              <Switch
                id="project_confidential"
                checked={projectConfidential}
                sx={{ ml: 1 }}
                inputProps={{
                  "aria-label": "switch nouveau projet non-public",
                }}
                onChange={(event) => setProjectConfidential(event.target.checked)}
              />
            }
          />
        </DialogContent>
        {/* ACTIONS BUTTONS */}
        <DialogActions>
          <Button color="inherit" onClick={() => setOpenDialog(false)}>
            Annuler
          </Button>
          <Button
            data-cy="confirmNewProjectButton"
            variant="text"
            onClick={handleProjectCreation}
            disabled={!projectName}
          >
            Créer
          </Button>
        </DialogActions>
      </Dialog>
      {/* SNACKBARALERTS REQUEST FEEDBACK */}
      {postProjectMutation.isError && (
        <SnackbarAlert
          severity={"error"}
          title={"Erreur lors de la création du projet"}
          message={postProjectMutation.error.message}
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          from={"newProjectButton"}
        />
      )}
      {postProjectMutation.isSuccess && (
        <SnackbarAlert
          severity={"success"}
          title={"Nouveau projet créé"}
          message={"Un nouveau projet a bien été créé"}
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          from={"newProjectButton"}
        />
      )}
    </>
  );
}

// Modules imports
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import { Archive, Unarchive } from "@mui/icons-material";

// App components
import SnackbarAlert from "../Status/SnackbarAlert";

// Requests imports
import { useKeycloak } from "@react-keycloak/web";
import { useMutation } from "react-query";
import apiClient from "../../services/axiosCfg";

/**
 * Component ArchiveProjectButton
 *
 * Handles archiving project (soft delete)
 *
 * @param {*} props
 * -
 * - project: project object
 *
 * @returns JSX.Element
 */
export default function ArchiveProjectButton(props) {
  const { project, handleNavigateBack } = props;
  const [open, setOpen] = useState(false); // Dialog open state (bool)
  const [getOpenSnackbar, setOpenSnackbar] = useState(false); // Snackbar open state (bool)

  // PREPARE REQUEST HEADERS WITH KEYCLOAK TOKEN
  const config = {
    headers: { Authorization: "Bearer " + useKeycloak().keycloak.token },
  };

  // Resquest for updating project
  const projectMutation = useMutation(() => {
    const body = { project: { archive: !project.archive } };
    return apiClient.put("/projects/" + project._id, body, config);
  });

  const handleConfirm = () => {
    projectMutation.mutate();
  };

  // If update project request error
  useEffect(() => {
    setOpen(false);
    setOpenSnackbar(projectMutation.isError);
  }, [projectMutation.isError]);

  // If update project request success
  useEffect(() => {
    setOpen(false);
    setOpenSnackbar(projectMutation.isSuccess);
    if (projectMutation.isSuccess) {
      handleNavigateBack();
    }
  }, [projectMutation.isSuccess]);

  ///////////////////////// Component render /////////////////////////////
  return (
    <>
      {/* BUTTON ARCHIVE */}
      <Box
        data-cy="archiveProjectButton"
        sx={{ display: "inline-flex", justifyContent: "center", alignItems: "center" }}
        onClick={() => setOpen(true)}
      >
        {project.archive ? <Unarchive color="warning" /> : <Archive color="warning" />}
        <Typography color="warning.main" ml={1}>
          {project.archive ? " Remettre" : " Archiver"}
        </Typography>
      </Box>
      {/* DIALOG CONFIRM ARCHIVE */}
      <Dialog data-cy="archiveProjectDialog" open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ mx: 4, mt: 1 }}>Êtes-vous sûr ?</DialogTitle>
        <DialogActions sx={{ mx: 4, mb: 1 }}>
          <Button autoFocus color="inherit" onClick={() => setOpen(false)}>
            Annuler
          </Button>
          <Button data-cy="confirmArchiveProjectButton" variant="text" color="warning" onClick={handleConfirm}>
            {project.archive ? "Remettre" : "Archiver"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* SNACKBARALERTS (handle request feedback) */}
      {projectMutation.isError && (
        <SnackbarAlert
          severity={"error"}
          title={"Erreur lors de l'archivage du projet"}
          message={projectMutation.error.message}
          open={getOpenSnackbar}
          setOpen={setOpenSnackbar}
          from={"SaveProjectButton"}
        />
      )}
      {projectMutation.isSuccess && (
        <SnackbarAlert
          severity={"success"}
          title={!project.archive ? " Projet archivé" : "Projet remis"}
          message={!project.archive ? "Le projet à bien été archivé" : "Le projet à bien été remis"}
          open={getOpenSnackbar}
          setOpen={setOpenSnackbar}
          from={"SaveProjectButton"}
        />
      )}
    </>
  );
}

ArchiveProjectButton.propTypes = {
  project: PropTypes.object.isRequired,
  handleNavigateBack: PropTypes.func.isRequired,
};

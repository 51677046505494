// Modules imports
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import PropTypes from "prop-types";

// App components
import SnackbarAlert from "../Status/SnackbarAlert";

// Requests imports
import { useKeycloak } from "@react-keycloak/web";
import { useMutation } from "react-query";
import apiClient from "../../services/axiosCfg";

/**
 * Component SaveProjectButton
 *
 * Handles project updating in database
 *
 * @param {*} props
 * -
 * - project: Project object to save
 *
 * @returns JSX.Element
 */
export default function SaveProjectButton(props) {
  const { project } = props;
  const [getOpenSnackbar, setOpenSnackbar] = useState(false);

  // function that check if project object is valid
  const isValidProject = () => {
    // project name can't be empty
    if (!project.name) {
      return false;
    }

    // project has to have a project manager
    if (!project.projectManager) {
      return false;
    }

    // project milestones name must not be empty
    const milestonesEmptyNames = project.milestones.filter((milestone) => milestone.name === "");
    if (milestonesEmptyNames.length > 0) {
      return false;
    }

    // Create milestonesInvalidDate array
    // project milestones date have to respect :
    //  - valid date (Date object)
    //  - startDate < endDate
    const milestonesInvalidDate = project.milestones.filter((milestone) => {
      // if start or end are'nt Date object
      if (!(milestone.endDate instanceof Date) || !(milestone.startDate instanceof Date)) {
        return true; // milestone invalid
      }
      // if start or end is invalid
      if (milestone.endDate.toString() === "Invalid Date" || milestone.startDate.toString() === "Invalid Date") {
        return true; // milestone invalid
      }
      if (
        new Date(milestone.endDate) > new Date("2100-01-01") ||
        new Date(milestone.startDate) < new Date("2010-01-01")
      ) {
        return true;
      }
      // if start < end
      return new Date(milestone.endDate) - new Date(milestone.startDate) < 0;
    });
    // if project constains invalid milestones object
    if (milestonesInvalidDate.length > 0) {
      return false; // project invalid
    }

    return true; // if all checks are ok -> project valid
  };

  // PREPARE REQUEST HEADERS WITH KEYCLOAK TOKEN
  const config = {
    headers: { Authorization: "Bearer " + useKeycloak().keycloak.token },
  };

  // Request for updating project
  const projectMutation = useMutation(() => {
    const body = { project: project };
    return apiClient.put("/projects/" + project._id, body, config);
  });

  useEffect(() => {
    setOpenSnackbar(projectMutation.isError);
  }, [projectMutation.isError]);

  useEffect(() => {
    setOpenSnackbar(projectMutation.isSuccess);
  }, [projectMutation.isSuccess]);

  ///////////////////////// Component render /////////////////////////////
  return (
    <>
      <LoadingButton
        data-cy="saveProjectButton"
        loading={projectMutation.isLoading}
        loadingPosition="end"
        color="primary"
        // type="submit"
        disabled={!isValidProject()}
        aria-label="mettre à jour projet"
        variant="contained"
        onClick={projectMutation.mutate}
        endIcon={<Save />}
      >
        Save
      </LoadingButton>
      {projectMutation.isError && (
        <SnackbarAlert
          severity={"error"}
          title={"Erreur de mise à jour du projet"}
          message={projectMutation.error.message}
          open={getOpenSnackbar}
          setOpen={setOpenSnackbar}
          from={"SaveProjectButton"}
        />
      )}
      {projectMutation.isSuccess && (
        <SnackbarAlert
          severity={"success"}
          title={"Projet mis à jour"}
          message={"Les données modifiées ont bien été enregistrées"}
          open={getOpenSnackbar}
          setOpen={setOpenSnackbar}
          from={"SaveProjectButton"}
        />
      )}
    </>
  );
}

SaveProjectButton.propTypes = {
  project: PropTypes.object.isRequired,
};

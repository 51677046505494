// Modules imports
import PropTypes from "prop-types";
import { Alert, AlertTitle, Snackbar, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

/**
 * Component SnackbarAlert
 *
 * displays snackbar alerts for requests feeback
 *
 * @param {*} props
 * -
 * - severity : severity of the Alert ("success", "warn", "error")
 * - title : string title of SnackbarAlert
 * - message : body message of SnackbarAlert
 * - open : bool SnackbarAlert display
 * - setOpen : callback function to set open
 * - from : which component calls SnackbarAlert ? used for Snackbar key props
 *
 * @returns JSX.Element
 */
export default function SnackbarAlert(props) {
  const { severity, title, message, open, setOpen, from } = props;

  return (
    <Snackbar
      autoHideDuration={6000}
      onClose={() => {
        setOpen(false);
      }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      key={"SnackbarAlert" + from}
    >
      <Alert
        severity={severity}
        action={
          <IconButton
            aria-label={"SnackbarAlertCloseIcon" + from}
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>{title ? title : severity}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
}

SnackbarAlert.propTypes = {
  severity: PropTypes.string.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
};

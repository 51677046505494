// Modules imports
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDrag, useDrop } from "react-dnd";
import { Divider, Grid, MenuItem, Select, TextField } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";

// App components
import { ItemTypes } from "./ItemTypes";
import DeleteMilestoneButton from "../buttons/DeleteMilestoneButton";
import StatusList from "../Status/StatusList";

/**
 * Component ProjectDetailsMilestoneItemAdmin
 *
 * displays an Item of project milestones list (also an item to drag and drop)
 *
 * @param {*} props
 * -
 * - startId : string for components ids
 * - milestone : project milestones list milestone object to display
 * - index: milestone index in milestones list
 * - moveItem: callback function that help with dnd
 * - handleChangeMilestone : callback function to change milestone in project object
 * - deleteOneMilestone : callback function to delete milestone
 *
 * @returns JSX.Element
 */
export default function ProjectDetailsMilestoneItemAdmin(props) {
  const { startId, milestone, index, moveItem, handleChangeMilestone, deleteOneMilestone } = props;
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [milestoneNameError, setMilestoneNameError] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (milestone.startDate && !(milestone.startDate instanceof Date)) {
      if (milestone.startDate.includes("T") && milestone.startDate.includes("Z")) {
        handleChangeMilestone(index, "startDate", new Date(milestone.startDate));
      }
    }
    if (milestone.endDate && !(milestone.endDate instanceof Date)) {
      if (milestone.endDate.includes("T") && milestone.endDate.includes("Z")) {
        handleChangeMilestone(index, "endDate", new Date(milestone.endDate));
      }
    }
  }, [milestone]);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: () => {
      return { startId, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 1 : 1;

  drag(drop(ref));

  return (
    <Grid container ref={ref} style={{ opacity }} py={2} pl={4} pr={3} data-handler-id={handlerId}>
      {/* MILESTONE NAME */}
      <Grid item xs={3} display={"flex"} alignItems={"center"}>
        <TextField
          id={startId + "_name"}
          data-cy={"milestoneListItem_" + index + "_name"}
          variant="standard"
          value={milestone.name}
          error={milestoneNameError}
          helperText={milestoneNameError ? "Obligatoire" : null}
          onChange={(event) => {
            setMilestoneNameError(!event.target.value);
            handleChangeMilestone(index, "name", event.target.value);
          }}
        />
      </Grid>
      {/* MILESTONE START DATE */}
      <Grid item xs={3} display={"flex"} alignItems={"center"}>
        <DatePicker
          id={startId + "_startDate"}
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              helperText={startDateError}
              data-cy={"milestoneListItem_" + index + "_startDate"}
            />
          )}
          value={milestone.startDate}
          error={!!startDateError}
          minDate={new Date("2010-01-01")}
          maxDate={milestone.endDate ? new Date(milestone.endDate) : null}
          onError={(reason) => {
            switch (reason) {
              case "invalidDate":
                setStartDateError("format de la date invalide");
                break;

              case "minDate":
                setStartDateError(`date incorrecte`);
                break;

              case "maxDate":
                setStartDateError(`startDate < endDate`);
                break;

              default:
                setStartDateError("");
            }
          }}
          onChange={(newValue) => {
            handleChangeMilestone(index, "startDate", newValue ? newValue : null);
          }}
        />
      </Grid>

      {/* MILESTONE END DATE */}
      <Grid item xs={3} display={"flex"} alignItems={"center"}>
        <DatePicker
          id={startId + "_endDate"}
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              helperText={endDateError}
              data-cy={"milestoneListItem_" + index + "_endDate"}
            />
          )}
          value={milestone.endDate}
          error={!!endDateError}
          minDate={milestone.startDate ? new Date(milestone.startDate) : null}
          maxDate={new Date("2100-01-01")}
          onError={(reason) => {
            switch (reason) {
              case "invalidDate":
                setEndDateError("format de la date invalide");
                break;

              case "minDate":
                setEndDateError(`startDate < endDate`);
                break;

              case "maxDate":
                setEndDateError(`date incorrecte`);
                break;

              default:
                setEndDateError("");
            }
          }}
          onChange={(newValue) => {
            handleChangeMilestone(index, "endDate", newValue ? newValue : null);
          }}
        />
      </Grid>
      {/* MILESTONE STATUS */}
      <Grid item xs={2} display={"flex"} justifyContent="center" alignItems={"center"}>
        <Select
          data-cy={"milestoneListItem_" + index + "_status"}
          id={startId + "_status"}
          variant="standard"
          disableUnderline
          value={milestone.status}
          onChange={(event) => {
            handleChangeMilestone(index, "status", event.target.value);
          }}
        >
          <MenuItem key={startId + "_status_MI_0"} value={0}>
            <StatusList status={0} />
          </MenuItem>
          <MenuItem key={startId + "_status_MI_1"} value={1}>
            <StatusList status={1} />
          </MenuItem>
          <MenuItem key={startId + "_status_MI_2"} value={2}>
            <StatusList status={2} />
          </MenuItem>
        </Select>
      </Grid>
      {/* MILESTONE DELETE BUTTON */}
      <Grid item xs={1} display={"flex"} justifyContent={"flex-end"} alignItems="center">
        <Divider orientation="vertical" variant="middle" sx={{ mr: 2 }} />
        <DeleteMilestoneButton
          deleteOneMilestone={deleteOneMilestone}
          index={index}
          key={startId}
          milestoneName={milestone.name}
        />
      </Grid>
    </Grid>
  );
}

ProjectDetailsMilestoneItemAdmin.propTypes = {
  startId: PropTypes.string.isRequired,
  milestone: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  moveItem: PropTypes.func.isRequired,
  handleChangeMilestone: PropTypes.func.isRequired,
  deleteOneMilestone: PropTypes.func.isRequired,
};

import * as React from 'react';
import pkg from '../../../package.json';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Button,
  createTheme,
  Drawer,
  styled,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Archive,
  CalendarViewDay,
  DoneAll,
  EventNote,
  Reorder,
  RocketLaunch,
} from '@mui/icons-material';

// Requests imports
import {useKeycloak} from '@react-keycloak/web';

// App components
import SidebarList from './SidebarList';
import {useQuery} from 'react-query';
import apiClient from '../../services/axiosCfg';
import {useState} from 'react';

// code for the drawer style
const drawerWidth = 260; // Same in Topbar component

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const miTheme = createTheme({
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#FCE7E6',
            color: '#e1251b',

            '&:hover': {
              backgroundColor: '#FEF3F3',
            },
          },
          '&:hover': {
            color: '#e1251b',
            backgroundColor: '#FEF3F3',
          },
          borderRadius: 8,
        },
      },
    },
  },
});

const sidebarMenus = {
  Accueil: {Planning: {icon: <CalendarViewDay/>, route: '/'}},
};

const sidebarMenusAuth = {
  'Mes Projets': {
    Tous: {icon: <Reorder/>, route: '/projects'},
    'Planifiés': {icon: <EventNote/>, route: '/projects/filter/planned'},
    'En cours': {icon: <RocketLaunch/>, route: '/projects/filter/progress'},
    'Terminés': {icon: <DoneAll/>, route: '/projects/filter/done'},
    'Archivés': {icon: <Archive/>, route: '/projects/filter/archived'},
  },
};

const sidebarMenusAdmin = {
  'Tous les projets': {
    Tous: {icon: <Reorder/>, route: '/projects/all'},
    Planifiés: {icon: <EventNote/>, route: '/projects/all/filter/planned'},
    'En cours': {icon: <RocketLaunch/>, route: '/projects/all/filter/progress'},
    Terminés: {icon: <DoneAll/>, route: '/projects/all/filter/done'},
    Archivés: {icon: <Archive/>, route: '/projects/all/filter/archived'},
  },
};

/**
 * Component Sidebar
 *
 * higher-order component that displays sidebar and child passed in props
 *
 * @param {*} props
 * -
 * - open: bool - sidebar display
 * - children: child to display
 * - isUserAdmin: bool if authentified user is admin
 *
 * @returns JSX.Element
 */
export default function Sidebar(props) {
  const {open, children, isUserAdmin} = props;
  const navigate = useNavigate();
  const {keycloak} = useKeycloak();
  const [selectedItem, setSelectedItem] = useState('');
  const [userIsManager, setUserIsManager] = useState(false);

  const handleClickItem = (index, route) => {
    setSelectedItem(index);
    navigate(route);
  };

  useQuery(
      "gerUserRole",
      async () => {
        return await apiClient.get("/special/userRoleByToken", {
          headers: { Authorization: "Bearer " + keycloak.token },
        });
      },
      {
        refetchOnWindowFocus: false,
        enabled: true,
        onSuccess: (res) => {
          setUserIsManager(["manager", "admin"].includes(res.data));
        },
        onError: (err) => {
          console.log(err);
        },
      }
  );

  return (
      <>
        <Drawer
            data-cy="sidebar"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: 'border-box',
                border: '2px whitesmoke dashed',
              },
              border: 'none',
            }}
            variant="persistent"
            open={open}
        >
          <Toolbar/>
          <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                overflow: 'auto',
              }}
          >
            <div>
              <ThemeProvider theme={miTheme}>
                {/* public list */}
                <SidebarList menus={sidebarMenus} selectedItem={selectedItem}
                             handleClick={handleClickItem}/>
                {/* authentified users list */}
                {(userIsManager || isUserAdmin) && (
                    <SidebarList menus={sidebarMenusAuth} selectedItem={selectedItem}
                                 handleClick={handleClickItem}/>
                )}
                {/* authentified admin users list */}
                {isUserAdmin && (
                    <SidebarList menus={sidebarMenusAdmin} selectedItem={selectedItem}
                                 handleClick={handleClickItem}/>
                )}
              </ThemeProvider>
            </div>

            <Box sx={{my: 2, textAlign: 'center', color: 'gray'}}>
              {(userIsManager || isUserAdmin) && (
                  <Button
                      target={"_blank"}
                      href="/docs/doc_utilisation_manager/Documentation d’utilisation de l’application web B f3585f5190804360ba057831328a8e05.html"
                      sx={{mb: 2}}
                      size="small"
                      variant="outlined"
                      color="inherit"
                  >{`Documentation d'utilisation`}</Button>
              )}
              <Typography fontWeight="bold" variant="button"
                          pb={2}>{`version : ${pkg.version}`}</Typography>
            </Box>
          </Box>
        </Drawer>
        <Main open={open}>
          <DrawerHeader/>
          {children}
        </Main>
      </>
  );
}

Sidebar.propTypes = {
  children: PropTypes.element,
  open: PropTypes.bool,
  isUserAdmin: PropTypes.bool.isRequired,
};

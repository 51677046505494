// Modules imports
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import { Alert, CircularProgress, Button, Typography, Paper, Box } from "@mui/material";

// Requests imports
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";
import apiClient from "../services/axiosCfg";

// App components
import ProjectDetailsAdmin from "./ProjectDetailsAdmin";
import ProjectdetailsManager from "./ProjectDetailsManager";

/**
 * Component ProjectDetails
 *
 * Container component for admin/manager project details page. Handle get project request and check user role
 */
export default function ProjectDetails() {
  const params = useParams(); // allows to retrieve project id from projects list page
  const [getProject, setProject] = useState(""); // query success response AND project object used to store updated project data
  const [getErrors, setErrors] = useState(""); // query error
  // Bool for user role
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const navigate = useNavigate();

  // handle change for all inputs of the project details form (even nested components (via callback functions))
  const handleChangeProject = (key, newData) => {
    setProject((prevState) => ({
      ...prevState,
      [key]: newData,
    }));
  };

  // DND PBS TEST
  // const handleChangeProject = useCallback((key, newData) => {
  //   setProject((prevState) => ({
  //     ...prevState,
  //     [key]: newData,
  //   }));
  // }, []);

  // Navigate back to projects list route
  const handleNavigateBack = () => {
    navigate(-1);
  };

  // PREPARE REQUEST HEADERS WITH KEYCLOAK TOKEN
  const config = {
    headers: { Authorization: "Bearer " + useKeycloak().keycloak.token },
  };

  // Request get user role by token
  useQuery("getUserRoleByToken", () => apiClient.get("/special/userRoleByToken", config), {
    enabled: true,
    onSuccess: (res) => setIsUserAdmin(res.data.user.role === "admin"),
    onError: (err) => console.log(err),
  });

  // Request get one project
  const { isLoading } = useQuery(
    "getOneProject",
    async () => {
      return await apiClient.get("/projects/" + params.projectId, config);
    },
    {
      refetchOnWindowFocus: false,
      enabled: true,
      onSuccess: (res) => {
        setProject(res.data);
      },
      onError: (err) => {
        console.log(err);
        setErrors(err);
      },
    }
  );

  ///////////////////////// Component render /////////////////////////////
  return (
    <Box mx={4}>
      <div style={{ display: "flex", justifyContent: "start" }}>
        {/* BUTTON GO BACK */}
        <Button variant="text" color="inherit" startIcon={<ArrowBackIos />} onClick={handleNavigateBack} disableRipple>
          Retour à la liste
        </Button>
      </div>
      {isLoading ? ( // IF the get project request loading => display loading circle
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="inherit" />
        </div>
      ) : getErrors ? ( // ELSE IF loading ended and request return error => display error
        <Alert severity="error">
          Désolé une erreur est survenue lors de la récupération du projet |{getErrors.toString()}
        </Alert>
      ) : !getProject ? ( // ELSE IF no error but request return nothing => display message
        <Typography variant="h5">Aucun projet à afficher</Typography>
      ) : (
        // ELSE request ok and returns project object => display context project details component
        <Paper
          data-cy="projectDetailsPaper"
          elevation={0}
          sx={{
            filter: "drop-shadow(0 0rem 1mm lightgray)",
            p: 3,
          }}
        >
          {getProject.archive && (
            <Paper
              data-cy="projectDetailsArchived"
              elevation={0}
              sx={{
                backgroundColor: "lemonchiffon",
                mb: 2,
                p: 1,
                mt: -3,
                mx: -3,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <Typography align="center">Archive</Typography>
            </Paper>
          )}
          {isUserAdmin ? (
            <ProjectDetailsAdmin
              getProject={getProject}
              handleChangeProject={handleChangeProject}
              handleNavigateBack={handleNavigateBack}
            />
          ) : (
            <ProjectdetailsManager
              getProject={getProject}
              handleChangeProject={handleChangeProject}
              handleNavigateBack={handleNavigateBack}
            />
          )}
        </Paper>
      )}
    </Box>
  );
}

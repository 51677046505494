// Modules imports
import * as React from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Alert,
  CircularProgress,
  TablePagination,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";

// Requests imports
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";
import apiClient from "../services/axiosCfg";

// App components
import StatusList from "../components/Status/StatusList";
import EnhancedTableHead from "../components/tables/EnhancedTableHead";
import TablePaginationActions from "../components/tables/TablePaginationActions";
import NewProjectButton from "../components/buttons/NewProjectButton";
import FilterListButton from "../components/buttons/FilterListButton";

/////////////////////////// Constantes /////////////////////////////////////////
const TradFiltres = {
  planned: { name: "Planifiés", color: "planned.main" },
  progress: { name: "En cours", color: "progress.main" },
  done: { name: "Terminés", color: "done.main" },
  archived: { name: "Archivés", color: "warning.main" },
};

/////////////////////////// Sorting functions //////////////////////////////////
function descendingComparator(a, b, sortBy) {
  if (b[sortBy] < a[sortBy]) {
    return -1;
  }
  if (b[sortBy] > a[sortBy]) {
    return 1;
  }
  return 0;
}

function getComparator(orderBy, sortBy) {
  return orderBy === "desc"
    ? (a, b) => descendingComparator(a, b, sortBy)
    : (a, b) => -descendingComparator(a, b, sortBy);
}

/**
 * Component ProjectsList
 *
 * Display projects list depending on the context [admin (all projects) / manager (only its projects)]
 *
 * @returns JSX.Element
 */
export default function ProjectsList(props) {
  const { adminMyProjects } = props;
  const params = useParams(); // allows to retrieve project id from projects list page
  const [getHeadCells, setHeadCells] = React.useState(null); // API predefined headcells array
  const [getProjects, setProjects] = React.useState(null); // Projects data Array
  const [getErrors, setErrors] = React.useState(null); // Errors from requests
  const [getSortBy, setSortBy] = React.useState("status"); // Sorting : column
  const [getOrderBy, setOrderBy] = React.useState("asc"); // Sorting : Order
  const [page, setPage] = React.useState(0); // Pagination
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // Pagination
  const keycloak = useKeycloak().keycloak;
  const [isUserAdmin, setIsUserAdmin] = React.useState(false);
  const navigate = useNavigate();
  const [filterButtonFilters, setFilterButtonFilters] = React.useState(["", ""]);

  // Sorting
  const handleRequestSort = (event, property) => {
    const isAsc = getSortBy === property && getOrderBy === "asc";
    setOrderBy(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  //// Pagination ////
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getProjects.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //// /Pagination ////

  // concat project manager name
  const fullName = (projectManager) => {
    return projectManager.firstname && projectManager.lastname
      ? projectManager.firstname + " " + projectManager.lastname
      : "undefined";
  };

  // PREPARE REQUEST HEADERS WITH KEYCLOAK TOKEN
  const config = {
    headers: { Authorization: "Bearer " + keycloak.token },
  };

  // Request get user role by token
  useQuery("getUserRoleByToken", () => apiClient.get("/special/userRoleByToken", config), {
    enabled: true,
    onSuccess: (res) => setIsUserAdmin(res.data.user.role === "admin"),
    onError: (err) => console.log(err),
  });

  // Request get all projects / get all project by projectManager
  const { isLoading } = useQuery(
    "getAllProjects",
    async () => {
      return await apiClient.get("/projects/list", config);
    },
    {
      enabled: true,
      onSuccess: (res) => {
        res.data.columns.length > 0 && setHeadCells(res.data.columns);
        res.data.projects.length > 0 && setProjects(res.data.projects);
      },
      onError: (err) => {
        setErrors(err);
        console.log(err);
      },
    }
  );

  const handleAdminMyProjectsFilter = (
    project // filter for special case (dispaly only projects where admin is also project manager)
  ) =>
    adminMyProjects
      ? project.projectManager
        ? project.projectManager.email === keycloak.tokenParsed.email
        : false
      : project;

  const handleFilterButton = (project) => {
    let bool = true;
    if (filterButtonFilters[0] !== "") {
      if (!project.name.toLowerCase().includes(filterButtonFilters[0].toLowerCase())) {
        bool = false;
      }
    }
    if (filterButtonFilters[1] !== "") {
      if (!fullName(project.projectManager).toLowerCase().includes(filterButtonFilters[1].toLowerCase())) {
        bool = false;
      }
    }
    return bool;
  };

  /**
   * takes a project in parameter and, according to the filter chosen with the
   * route parameter, returns true or false if it matches the filter
   *
   * @param {object} project a project of projects array
   * @returns bool
   */
  const handleFilterParameter = (project) => {
    switch (
      params.filter ? params.filter : "default" // params.filter => route parameter
    ) {
      case "planned":
        return !project.archive && project.status === 0;
      case "progress":
        return !project.archive && project.status === 1;
      case "done":
        return !project.archive && project.status === 2;
      case "archived":
        return !!project.archive;
      default:
        return !project.archive;
    }
  };

  ///////////////////////// Component render /////////////////////////////
  return isLoading ? ( // IF the get all projects is loading => display loading circle
    <CircularProgress color="primary" sx={{ textAlign: "center" }} />
  ) : getErrors ? ( // ELSE IF loading ended and request returns error => display error
    <Alert severity="error">
      Désolé une erreur est survenue lors de la récupération des projets |{getErrors.toString()}
    </Alert>
  ) : getProjects ? ( // ELSE IF no error and returns projects array
    //  LIST CONTAINER
    <Paper
      data-cy="ProjectsListPaperContainer"
      elevation={0}
      sx={{ filter: "drop-shadow(0 0rem 1mm lightgray)", margin: 4 }}
    >
      {/* HEAD LIST */}
      <Toolbar
        sx={{
          display: "inline-flex",
          justifyContent: "space-between",
          background: "default",
          width: "100%",
        }}
      >
        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
          {/* LIST TITLE */}
          <Typography variant="h6" noWrap>{`Liste des projets`}</Typography>
          {/* LIST SUBTITLE */}
          <Typography
            variant="overline"
            color={TradFiltres[params.filter] ? TradFiltres[params.filter].color : "black"}
            data-cy="projectsListFilterTypography"
            mt={0.5}
            ml={2}
          >{`${TradFiltres[params.filter] ? TradFiltres[params.filter].name : "Tous"}`}</Typography>
        </Box>
        {/* NEW PROJECT BUTTON */}
        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
          {isUserAdmin && <NewProjectButton />}
          <Typography sx={{ ml: 1, mr: 1.5 }} style={{ fontWeight: "1000" }}>
            |
          </Typography>
          {/* FILTER LIST BUTTON */}
          <FilterListButton
            filterButtonFilters={filterButtonFilters}
            setFilterButtonFilters={setFilterButtonFilters}
            isUserAdmin={isUserAdmin}
          />
        </Box>
      </Toolbar>
      <TableContainer>
        <Table sx={{ minWidth: 650, tableLayout: "fixed" }} aria-label="liste des projets">
          {/* HEADCELLS ROW */}
          <EnhancedTableHead
            orderBy={getOrderBy}
            sortBy={getSortBy}
            onRequestSort={handleRequestSort}
            headCells={getHeadCells}
          />
          {/* PROJECT ROW */}
          <TableBody>
            {getProjects
              .filter((project) => handleAdminMyProjectsFilter(project)) // filter for special case (dispaly only projects where admin is also project manager)
              .filter((project) => handleFilterParameter(project)) // filter from route parameter (planned, progress, etc.)
              .filter((project) => handleFilterButton(project)) // filter for filters list
              .sort(getComparator(getOrderBy, getSortBy)) // Sort in table
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((project) => {
                return (
                  <TableRow key={"project" + project._id} onClick={() => navigate("/projects/" + project._id)} hover>
                    <TableCell align="left">{project.name}</TableCell>
                    <TableCell align="left">
                      {project.projectManager ? fullName(project.projectManager) : "Aucun"}
                    </TableCell>
                    <TableCell align="right">{<StatusList status={project.status} />}</TableCell>
                    <TableCell align="right">
                      {new Date(project.createdAt).toLocaleDateString("fr-CH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {/* END PROJECT ROW */}
        </Table>
      </TableContainer>
      {/* PAGINATION COMPONENT */}
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        colSpan={3}
        component={"div"}
        count={getProjects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "Projets par page",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      />
    </Paper>
  ) : (
    // ELSE IF no projects array
    <Box textAlign={"center"}>
      <Typography variant="h5">Aucun projet à afficher</Typography>
      {isUserAdmin && <NewProjectButton />}
    </Box>
  );
}
////////////////////////////////////////////////////////////////////////////////

ProjectsList.propTypes = {
  adminMyProjects: PropTypes.bool,
};

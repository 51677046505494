// Modules imports
import {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

// Requests imports
import {useKeycloak} from '@react-keycloak/web';
import {useQuery} from 'react-query';
import apiClient from '../services/axiosCfg';

// App components
import Sidebar from '../components/navigation/Sidebar';
import Topbar from '../components/navigation/Topbar';
import NotFound from '../pages/NotFound';
import ProjectDetails from '../pages/ProjectDetails';
import ProjectsList from '../pages/ProjectsList';
import ProjectsPlanning from '../pages/ProjectsPlanning';

/**
 * Component Router
 *
 * Handle app routing
 */
export default function Router() {
  const {keycloak} = useKeycloak();
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  // PREPARE REQUEST HEADERS WITH KEYCLOAK TOKEN
  const config = {
    headers: {Authorization: 'Bearer ' + keycloak.token},
  };

  // Request get user role by token
  const queryUserRole = useQuery('getUserRoleByToken',
      () => apiClient.get('/special/userRoleByToken', config), {
        enabled: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: (res) => {
          setIsUserAdmin(res.data.user.role === 'admin');
        },
        onError: (err) => console.log(err),
      });

  useEffect(() => {
    if (keycloak.authenticated) {
      queryUserRole.refetch();
    }
  }, [keycloak]);

  ///////////////////////// Component render /////////////////////////////
  if (!keycloak.authenticated) {
    return keycloak.login();
  }

  return (
      <BrowserRouter>
        <Topbar>
          <Sidebar isUserAdmin={isUserAdmin}>
            <Routes>
              <Route path="/" element={<ProjectsPlanning/>}/>
              <Route path="/projects"
                     element={<ProjectsList adminMyProjects={isUserAdmin}/>}/>
              <Route path="/projects/filter/:filter"
                     element={<ProjectsList adminMyProjects={isUserAdmin}/>}/>
              <Route path="/projects/:projectId" element={<ProjectDetails/>}/>

              {isUserAdmin && (
                  <>
                    <Route path="/projects/all" element={<ProjectsList/>}/>
                    <Route path="/projects/all/filter/:filter" element={<ProjectsList/>}/>
                  </>
              )}
              <Route path="*" element={<NotFound/>}/>
            </Routes>
          </Sidebar>
        </Topbar>
      </BrowserRouter>
  );
}

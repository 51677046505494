// Modules imports
import { useState, useEffect } from "react";
import { Alert, Button, ButtonGroup, CircularProgress, Grid, Paper, Typography } from "@mui/material";

// Requests imports
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";
import apiClient from "../services/axiosCfg";

// imports to handle dates
import DateFnsUtils from "@date-io/date-fns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

// App components
import PlanningGantt from "../components/charts/PlanningGantt";
import ProjectsPlanningDetails from "../components/parts/ProjectsPlanningDetails";

const ColorsStatusChartBar = ["#34568B", "#D66853", "#009B77"];

/**
 * Component ProjectsPlanning
 *
 * Page that display a planning of all the unconfidential and unarchived planned projects
 *
 * @returns JSX.Element
 */
export default function ProjectsPlanning() {
  const [projects, setProjects] = useState(""); // query success response AND project object used to store updated project data
  const [projectsDataPlanning, setProjectsDataPlanning] = useState("");
  const [errors, setErrors] = useState(""); // query error
  const [displayProjectIndex, setDisplayProjectIndex] = useState(null);
  const [offset, setOffset] = useState([-1, 1]);

  const dateNowWithMonthOffset = (monthOffset) => {
    let date = new Date(Date.now());
    date.setMonth(date.getMonth() + monthOffset);
    return date;
  };

  const handleChangeOffset = (num) => {
    setOffset(offset.map((off) => off + num));
  };

  const [planningOffset, setPlanningOffset] = useState([
    dateNowWithMonthOffset(offset[0]),
    dateNowWithMonthOffset(offset[1]),
  ]);

  useEffect(() => {
    setPlanningOffset([dateNowWithMonthOffset(offset[0]), dateNowWithMonthOffset(offset[1])]);
  }, [offset]);

  // console.log(offset);

  // PREPARE REQUEST HEADERS WITH KEYCLOAK TOKEN
  const config = {
    headers: { Authorization: "Bearer " + useKeycloak().keycloak.token },
  };

  // Request get one project
  const { isLoading } = useQuery(
    "getOneProject",
    async () => {
      return await apiClient.get("/projects/", config);
    },
    {
      refetchOnWindowFocus: false,
      enabled: true,
      onSuccess: (res) => {
        setProjectsDataPlanning(
          res.data.projects
            .map((project, index) => {
              // dateToDayString(project.startDate);
              const dataSet = {
                label: project.name,
                backgroundColor: ColorsStatusChartBar[project.status],
                borderRadius: 8,
                borderSkipped: false,
                data: [],
              };
              dataSet["data"][index] = [project.startDate, project.endDate];
              return dataSet;
            })
            .sort((a, b) => a.startDate - b.startDate)
        );
        setProjects(res.data.projects);
      },
      onError: (err) => {
        setErrors(err);
        console.log(err);
      },
    }
  );

  return (
    <div>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="inherit" />
        </div>
      ) : errors ? (
        <Alert severity="error">
          Désolé une erreur est survenue lors de la récupération du projet |{errors.toString()}
        </Alert>
      ) : projects.length > 0 ? (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4" mb={3}>
                Planning des projets
              </Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="end" alignItems="center" pr={2}>
              <Typography variant="overline" mr={2}>
                {planningOffset[0].toLocaleDateString("fr-CH", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }) +
                  " - " +
                  planningOffset[1].toLocaleDateString("fr-CH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
              </Typography>
              <ButtonGroup disableElevation variant="contained" color="inherit" size="small" aria-label="outlined primary button group">
                <Button onClick={() => handleChangeOffset(-1)}>{"<"}</Button>
                <Button onClick={() => handleChangeOffset(1)}>{">"}</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Paper
            elevation={0}
            sx={{
              height: projects.length * 45 + 45,
              borderBottom: 1,
              borderColor: "lightGray",
              borderRadius: 0,
              padding: 1,
              marginBottom: 2,
              minWidth: 750,
            }}
          >
            <PlanningGantt
              projects={projectsDataPlanning}
              setDisplayProjectIndex={setDisplayProjectIndex}
              planningOffset={planningOffset}
            />
          </Paper>
          {Number.isInteger(displayProjectIndex) && (
            <ProjectsPlanningDetails project={projects[displayProjectIndex]}></ProjectsPlanningDetails>
          )}
        </LocalizationProvider>
      ) : (
        <Typography variant="h4" textAlign="center">
          {"Aucun projet planifié pour l'instant"}
        </Typography>
      )}
    </div>
  );
}

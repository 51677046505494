import { ListItem, ListItemText } from "@mui/material";
import PropTypes from "prop-types";

export default function SidebarListItems(props) {
  const { menuTitle, items, selectedItem, handleClick } = props;

  return Object.keys(items).map((itemTitle) => {
    const item = items[itemTitle];
    return (
      <ListItem
        key={"sidebarList" + menuTitle + "Item" + itemTitle}
        sx={{ mb: 0.5, color: "dimgray" }}
        button
        onClick={() => handleClick(menuTitle + itemTitle, item.route)}
        selected={selectedItem === menuTitle + itemTitle}
        color="inherit"
      >
        {item.icon}
        <ListItemText sx={{ ml: 2 }} primary={itemTitle} />
      </ListItem>
    );
  });
}

SidebarListItems.propTypes = {
  menuTitle: PropTypes.string.isRequired,
  items: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

// Modules imports
import { useState } from "react";
import PropTypes from "prop-types";
import { Settings } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";

// App components
import ArchiveProjectButton from "./ArchiveProjectButton";
import DeleteProjectButton from "./DeleteProjectButton";

/**
 * Component OptionsProjectButton
 *
 * Display a button that open a menuItem when clicked
 *
 * @param {*} props
 * -
 * - project: project object of project array
 * - isUserAdmin: bool that says if authenticated user is an admin
 *
 * @returns JSX.Element
 */
export default function OptionsProjectButton({ project, isUserAdmin, handleNavigateBack }) {
  const [anchorEl, setAnchorEl] = useState(null); // for menu

  // menu
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  ///////////////////////// Component render /////////////////////////////
  return (
    <>
      <IconButton data-cy="optionsProjectButton" onClick={handleMenu} sx={{ ml: 1 }}>
        <Settings />
      </IconButton>
      <Menu
        id="menu_settings_projectDetails"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        BackdropProps={{
          "data-cy": "projectOptionsMenuBackDrop",
          sx: { backgroundColor: "transparent" },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0 0.5rem 1rem lightgray)",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {/* If user is admin display archive project button */}
        {isUserAdmin && (
          <MenuItem sx={{ borderRadius: 1, mx: 1, my: 0.5, "&:hover": { backgroundColor: "#fff8e1" } }}>
            <ArchiveProjectButton project={project} handleNavigateBack={handleNavigateBack} />
          </MenuItem>
        )}
        {isUserAdmin && project.archive && (
          <MenuItem sx={{ borderRadius: 1, mx: 1, my: 0.5, "&:hover": { backgroundColor: "heig.bg" } }}>
            <DeleteProjectButton
              projectId={project._id}
              projectName={project.name}
              handleNavigateBack={handleNavigateBack}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

OptionsProjectButton.propTypes = {
  project: PropTypes.object.isRequired,
  isUserAdmin: PropTypes.bool.isRequired,
  handleNavigateBack: PropTypes.func.isRequired,
};

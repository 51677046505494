// Modules imports
import { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

/**
 * Component DeleteMilestoneButton
 *
 * Displays a delete button and handle milestone deletion
 *
 * @param {*} props
 * -
 * - deleteOneMilestone: callback function to remove milestone from project object milestones array
 * - index: milestone index in the project milestones array
 * - startId: to set iconButton component key "startId + _deleteBtn"
 * - milestoneName: name of the milestone (for dialog)
 *
 * @returns JSX.Element
 */
export default function DeleteMilestoneButton(props) {
  const { deleteOneMilestone, index, startId, milestoneName } = props;
  const [open, setOpen] = useState(false); // dialog open state

  ///////////////////////// Component render /////////////////////////////
  return (
    <>
      {/* BUTTON DELETE */}
      <IconButton
        data-cy={"milestone_" + index + "_deleteButton"}
        key={startId ? startId : "milestone" + "_deleteBtn"}
        size="small"
        edge="end"
        color="error"
        aria-label="supprimer projet"
        sx={{
          mx: 0,
          px: 0,
        }}
        onClick={() => setOpen(true)}
      >
        <Delete />
      </IconButton>
      {/* CONFIRM DELETION DIALOG */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Supprimer {milestoneName ? milestoneName : "un jalon / une étape"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir supprimer {milestoneName ? milestoneName : "ce jalon / cette étape"} ?
          </DialogContentText>
          <DialogContentText color="warning">Cette action est irréversible.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setOpen(false)}>
            Annuler
          </Button>
          <Button
            data-cy="confirmDeleteMilestoneButton"
            variant="text"
            color="error"
            onClick={() => deleteOneMilestone && deleteOneMilestone(index)}
            autoFocus
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeleteMilestoneButton.propTypes = {
  deleteOneMilestone: PropTypes.func,
  index: PropTypes.number,
  startId: PropTypes.string,
  milestoneName: PropTypes.string,
};

// Modules imports
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Input } from "@mui/material";
import { Upload } from "@mui/icons-material";

// Requests imports
import { useKeycloak } from "@react-keycloak/web";
import { useMutation } from "react-query";
import apiClient from "../../services/axiosCfg";

// App components
import SnackbarAlert from "../Status/SnackbarAlert";

/**
 * Component UploadProjectSheetButton
 *
 * displays a label and upload button. Handle upload file request
 *
 * @param {*} props
 * -
 * - projectId : id of the project to delete
 * - handleChangeProject : callback function to change project object
 *
 * @returns JSX.Element
 */
export default function UploadProjectSheetButton({ projectId, handleChangeProject }) {
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar open state (bool)
  const [file, setFile] = useState("");

  // PREPARE REQUEST HEADERS WITH KEYCLOAK TOKEN
  const config = {
    headers: { Authorization: "Bearer " + useKeycloak().keycloak.token },
  };

  const postProjectFileMutation = useMutation(() => {
    let formDatafile = new FormData();
    formDatafile.append(file.name, file);
    return apiClient.post(`/projects/${projectId}/file`, formDatafile, config);
  });

  const handleFileUpload = async (event) => {
    if (event.target.files.length > 0) {
      await setFile(event.target.files[0]);
      handleChangeProject("projectSheet", "uploading...");
      postProjectFileMutation.mutate();
    }
  };

  // If request is error
  useEffect(() => {
    setOpenSnackbar(postProjectFileMutation.isError);
  }, [postProjectFileMutation.isError]);

  // If request is success
  useEffect(() => {
    setOpenSnackbar(postProjectFileMutation.isSuccess);
    if (postProjectFileMutation.isSuccess) {
      handleChangeProject("projectSheet", postProjectFileMutation.data.data.projectSheet);
    }
  }, [postProjectFileMutation.isSuccess]);

  return (
    <label htmlFor="icon-button-file">
      <Input
        accept=".pdf, .docx, .doc, .xls, .xlsx"
        id="project_projectSheet_upload"
        type="file"
        onChange={handleFileUpload}
        sx={{ display: "none" }}
      />
      <IconButton data-cy="projectSheetUploadButton" color="primary" aria-label="upload project sheet" component="span">
        <Upload />
      </IconButton>
      {postProjectFileMutation.isError && (
        <SnackbarAlert
          severity={"error"}
          title={"Erreur upload fiche de projet"}
          message={postProjectFileMutation.error.message}
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          from={"UploadProjectSheetButton"}
        />
      )}
      {postProjectFileMutation.isSuccess && (
        <SnackbarAlert
          severity={"success"}
          title={"Upload fiche de projet"}
          message={"La fiche de projet a bien été uploadé"}
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          from={"UploadProjectSheetButton"}
        />
      )}
    </label>
  );
}

UploadProjectSheetButton.propTypes = {
  projectId: PropTypes.string.isRequired,
  handleChangeProject: PropTypes.func.isRequired,
};

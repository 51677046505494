// Modules imports
import { useState } from "react";
import PropTypes from "prop-types";
import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";

// App components
import StatusList from "../Status/StatusList";

/**
 * Component MilestonesListProjectDetailsManager
 *
 * Display project milestones list with the manager context (Limited rights to update project data)
 *
 * @param {*} props
 * -
 * - projectMilestones : milestone part of the project document from database
 * - handleChangeProject : callback function to update project object
 *
 * @returns JSX.Element
 */
export default function MilestonesListProjectDetailsManager(props) {
  const { projectMilestones, handleChangeProject } = props;
  const [milestones] = useState(projectMilestones);

  // pre-processing to update project object
  const handleChangeMilestone = (event) => {
    let updatedMilestones = milestones;
    updatedMilestones[event.target.id.split("_")[2]][event.target.id.split("_")[3]] = event.target.value;
    handleChangeProject("milestones", updatedMilestones);
  };

  // For inputs that don't return event
  const handleChangeMilestoneSpecial = (inputId, newValue) => {
    let fakeEvent = { target: { id: inputId, value: newValue } };
    handleChangeMilestone(fakeEvent);
  };

  // handle the database formatted date parsing
  const parsedDate = (dateDB) => {
    const dateObject = new Date(dateDB);
    return dateObject.toLocaleDateString();
  };

  ///////////////////////// Component render /////////////////////////////
  return (
    <Paper data-cy="MilestonesListManagerPaper" elevation={0} variant="outlined">
      {/* MILESTONES LIST TOP BAR */}
      <Toolbar sx={{ justifyContent: "space-between", backgroundColor: "whitesmoke" }}>
        <Typography variant="h6">Étapes et jalons</Typography>
      </Toolbar>
      {/* END MILESTONES LIST TOP BAR */}
      {/* MILESTONES LIST BODY */}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="Étapes et jalons">
          <TableHead>
            <TableRow>
              <TableCell key={"headCellMilestoneName"} align={"left"}>
                name
              </TableCell>
              <TableCell key={"headCellMilestoneStartDate"} align={"center"}>
                startDate
              </TableCell>
              <TableCell key={"headCellMilestoneEndDate"} align={"center"}>
                endDate
              </TableCell>
              <TableCell key={"headCellMilestoneStatus"} align={"center"}>
                status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {milestones.map((milestone, index) => {
              const startId = "milestone_" + milestone._id + "_" + index;
              return (
                <TableRow key={startId} hover>
                  <TableCell align="left">
                    <Typography data-cy={"milestoneListItem_" + index + "_name"} id={startId + "_name"}>
                      {milestone.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography data-cy={"milestoneListItem_" + index + "_startDate"} id={startId + "_startDate"}>
                      {parsedDate(milestone.startDate)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography data-cy={"milestoneListItem_" + index + "_endDate"} id={startId + "_endDate"}>
                      {parsedDate(milestone.endDate)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Select
                      data-cy={"milestoneListItem_" + index + "_status"}
                      id={startId + "_status"}
                      variant="standard"
                      disableUnderline
                      value={milestone.status}
                      onChange={(event) => {
                        handleChangeMilestoneSpecial(startId + "_status", event.target.value);
                      }}
                    >
                      <MenuItem key={startId + "_status_MI_0"} value={0}>
                        <StatusList status={0} />
                      </MenuItem>
                      <MenuItem
                        data-cy={"milestoneListItem_" + index + "_status_1"}
                        key={startId + "_status_MI_1"}
                        value={1}
                      >
                        <StatusList status={1} />
                      </MenuItem>
                      <MenuItem
                        data-cy={"milestoneListItem_" + index + "_status_2"}
                        key={startId + "_status_MI_2"}
                        value={2}
                      >
                        <StatusList status={2} />
                      </MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* END MILESTONES LIST BODY */}
    </Paper>
  );
}

MilestonesListProjectDetailsManager.propTypes = {
  projectMilestones: PropTypes.array.isRequired,
  handleChangeProject: PropTypes.func.isRequired,
};

import { createTheme, ThemeProvider, colors } from "@mui/material";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";

// App components
import KeycloakInit from "./KeycloakInit";

function App() {
  // Mui theming
  const theme = createTheme({
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: ["Arial"],
    },
    palette: {
      warning: {
        main: "#ffa000",
        contrastText: "#fff",
      },
      planned: {
        main: "#34568B",
        contrastText: "#fff",
      },
      progress: {
        main: "#D66853",
        contrastText: "#fff",
      },
      done: {
        main: "#009B77",
        contrastText: "#fff",
      },
      white: {
        main: "#ffffff",
      },
      shade: {
        main: "lightgray",
        bg: "whitesmoke",
      },
      primary: {
        main: colors.blue[500],
        bg: colors.blue[50],
      },
      heig: {
        main: "#e1251b",
        bg: "#FCE7E6",
        hover: "FEF3F3",
        contrastText: "#fff",
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.blue[500],
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "lightgray",
              borderWidth: 1,
            },
            "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
              color: colors.blue[500],
            },
            "&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
              color: colors.blue[500],
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "auto",
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            // color: colors.blue[500],
            ".MuiTableSortLabel-icon": {
              opacity: 0,
              color: colors.blue[500],
            },
            "&:hover": {
              // color: colors.blue[500],
              "&.MuiTableSortLabel-icon": {
                opacity: 1,
                color: colors.blue[500],
              },
            },
            "&.Mui-active": {
              // color: colors.blue[500],
              ".MuiTableSortLabel-icon": {
                opacity: 1,
                color: colors.blue[500],
              },
            },
          },
        },
      },
    },
  });

  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <KeycloakInit />
        </div>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
